import { EBoxModel } from './boxModel'
import {
  DefaultCameraConfiguration,
  ICameraConfiguration
} from './camera_configuration'
import {
  DefaultMqttConfiguration,
  IIoTriggerConfiguration,
  IMqttConfiguration
} from './eventdataOutputConfiguration'
import { IStreamStatus } from './streamStatus'

export interface IStream {
  id: string // stream UUID
  name?: string // Friendly name of the stream
  model?: EBoxModel
  streamStatus?: IStreamStatus
  coordinates?: ICoordinates
  enabled: boolean
  onvifAvailable: boolean
  ioOutPins?: number
  debug?: boolean
  recordTrackCalibration?: boolean
}

export interface IStreamInfo {
  boxName: string
  boxId: string
  streamName?: string
  streamId: string
  readOnly: boolean
  enabled: boolean
  hasAnpr: boolean
  hasGdpr: boolean
  streamCoordinates?: ICoordinates
  streamStatus?: IStreamStatus
  debug: boolean
  recordTrackCalibration?: boolean
}

export interface ICoordinates {
  latitude: number
  longitude: number
}

export const DefaultStream: IStream = {
  id: '',
  name: undefined,
  model: undefined,
  streamStatus: undefined,
  coordinates: undefined,
  enabled: true,
  onvifAvailable: false,
  ioOutPins: undefined,
  debug: false,
  recordTrackCalibration: false
}

export interface IStreamDetails {
  streamId: string
  cameraConfig: ICameraConfiguration
  mqttConfig: [IMqttConfiguration]
  ioTriggerConfig?: IIoTriggerConfiguration
}

export const DefaultStreamDetails: IStreamDetails = {
  streamId: '',
  cameraConfig: DefaultCameraConfiguration,
  mqttConfig: [DefaultMqttConfiguration]
}
