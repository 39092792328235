import React, { Component } from 'react'
import { ECameraConfigurationType } from '../types/camera_configuration'
import {
  DefaultMqttConfiguration,
  EMqttConfigurationType,
  IIoTriggerConfiguration
} from '../types/eventdataOutputConfiguration'
import { connect } from 'react-redux'
import { loadCameraFrame, resetCameraFrame } from '../redux/actions/cameraFrame'
import {
  loadAllStreamDetails,
  saveStreamDetails
} from '../redux/actions/streamDetails'
import { withTranslation } from 'react-i18next'
import StreamConfigurationForm from '../components/StreamConfigurationForm'
import { Accordion } from 'carbon-components-react'
import {
  DefaultStream,
  DefaultStreamDetails,
  IStream,
  IStreamDetails
} from '../types/stream'
import { DeviceType, IBox } from '../types/box'
import _ from 'lodash'
import { ICameraFrame } from '../types/cameraFrame'
import { ConfigurationConfirmationDialog } from '../components/ConfirmDialog/ConfigurationConfirmationDialog'
import { notify } from '../services/notify'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { Button, Tooltip } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { DeleteConfirmationDialog } from '../components/ConfirmDialog/DeleteConfirmationDialog'
import { deleteStream } from '../redux/actions/streams'
import { Config } from '../services/config'
import { callFetchLicenseStatus } from '../redux/actions/licenses'
import { ILicenseStatus } from '../types/licenseManagement'
import { userIsAtLeast, UserRole } from '../types/user_role'

interface IStreamConfigurationContainerProps {
  streams: [IStream]
  streamdetails: IStreamDetails[]
  licensesStatus: ILicenseStatus
  box: IBox
  isSubmitting: boolean
  loadAllStreamDetails: (boxId: string) => Promise<void>
  saveStreamDetails: (boxId: string, streamId: string, data) => Promise<void>
  callFetchLicenseStatus: () => Promise<void>
  deleteStream: (boxId: string, streamId: string) => Promise<void>

  loadCameraFrame: (
    boxId: string,
    streamId: string,
    forceRefresh: boolean,
    calibration?: boolean,
    timestamp?: string
  ) => void
  resetCameraFrame: Function
  cameraFrames: ICameraFrame[]
  loadingIds: boolean[]
  t: any
}

const LOCAL_STORAGE_HIDEBLUR = 'hideBlurNote'

interface IStreamConfigurationContainerState {
  isConfirmationDialogVisible: boolean
  isDeleteDialogVisible: boolean
  isLoading: boolean
  updatedStreamDetails: IStreamDetails
  deleteStreamDetails
  streamdetails: IStreamDetails[]
  newStreams: IStream[]
  hideBlurNote: boolean
}

class StreamConfigurationContainer extends Component<
  IStreamConfigurationContainerProps,
  IStreamConfigurationContainerState
> {
  state

  constructor(props) {
    super(props)
    this.state = {
      isConfirmationDialogVisible: false,
      isDeleteDialogVisible: false,
      isLoading: true,
      streamdetails: [],
      updatedStreamDetails: DefaultStreamDetails,
      newStreams: [],
      hideBlurNote: localStorage.getItem(`${LOCAL_STORAGE_HIDEBLUR}`) === 'true'
    }
    this.handleStreamConfigurationFormSubmit = this.handleStreamConfigurationFormSubmit.bind(
      this
    )
    this.handleStreamDelete = this.handleStreamDelete.bind(this)
    this.loadCameraFrameForStream = this.loadCameraFrameForStream.bind(this)
    this.saveStreamConfig = this.saveStreamConfig.bind(this)
    this.deleteStreamConfig = this.deleteStreamConfig.bind(this)
    this.addNewStream = this.addNewStream.bind(this)
    this.setHideBlurNote = this.setHideBlurNote.bind(this)
    this.isNewStream = this.isNewStream.bind(this)
    this.maxStreamsReached = this.maxStreamsReached.bind(this)
    this.handleCameraConfigTypeChanged = this.handleCameraConfigTypeChanged.bind(
      this
    )
    this.handleMqttConfigTypeChanged = this.handleMqttConfigTypeChanged.bind(
      this
    )
    this.handleMqttCompressionChanged = this.handleMqttCompressionChanged.bind(
      this
    )
    this.handleMqttQosChanged = this.handleMqttQosChanged.bind(this)
    this.handleIoTriggerConfigChanged = this.handleIoTriggerConfigChanged.bind(
      this
    )
    this.onCloseDialog = this.onCloseDialog.bind(this)
  }

  componentDidMount() {
    this.loadData()
    // reset camera frame for each stream
    this.props.streams.forEach((stream) => {
      this.props.resetCameraFrame(stream.id)
    })
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(
        prevProps.streams.filter((x) => !this.isNewStream(x.id)),
        this.props.streams.filter((x) => !this.isNewStream(x.id))
      ) &&
      !this.state.isLoading
    ) {
      this.loadData()
    }
  }

  loadData() {
    this.setState({ isLoading: true })
    !this.props.licensesStatus && this.props.callFetchLicenseStatus()
    this.props.loadAllStreamDetails(this.props.box.id).then(
      (_) => {
        this.setState({
          isLoading: false,
          streamdetails: this.props.streamdetails // pull state from redux
        })
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.log('Error loading streamdetails ' + error)
      }
    )
  }

  handleMqttCompressionChanged(compressionToggled: boolean, streamId: string) {
    let streamConfig = this.state.streamdetails[streamId]

    streamConfig.mqttConfig.forEach((mqttConfig) => {
      if (mqttConfig.configurationType === EMqttConfigurationType.custom) {
        mqttConfig.compression = compressionToggled
      }
    })

    let stream = {}
    stream[streamId] = streamConfig
    this.setState({ ...this.state.streamdetail, stream })
  }

  handleMqttQosChanged(qos: number, streamId: string) {
    let streamConfig = this.state.streamdetails[streamId]

    streamConfig.mqttConfig.forEach((mqttConfig) => {
      if (mqttConfig.configurationType === EMqttConfigurationType.custom) {
        mqttConfig.qos = qos
      }
    })

    let stream = {}
    stream[streamId] = streamConfig
    this.setState({ ...this.state.streamdetail, stream })
  }

  handleMqttConfigTypeChanged(
    mqttConfigurationTypes: [EMqttConfigurationType],
    streamId: string
  ) {
    let streamConfig = this.state.streamdetails[streamId]

    // remove configurations
    streamConfig.mqttConfig = streamConfig.mqttConfig.filter((config) => {
      return mqttConfigurationTypes.includes(config.configurationType)
    })

    // add new configuration
    mqttConfigurationTypes.forEach((type) => {
      let config = streamConfig.mqttConfig.find((config) => {
        return config.configurationType === type
      })
      if (!config) {
        let defaultConfig = Object.assign({}, DefaultMqttConfiguration)
        defaultConfig.configurationType = type
        streamConfig.mqttConfig.push(defaultConfig)
      }
    })

    let stream = {}
    stream[streamId] = streamConfig
    this.setState({ ...this.state.streamdetail, stream })
  }

  handleIoTriggerConfigChanged(
    ioTriggerConfig: IIoTriggerConfiguration,
    streamId: string
  ) {
    let streamConfig = this.state.streamdetails[streamId]
    streamConfig.ioTriggerConfig = ioTriggerConfig
    let stream = {}
    stream[streamId] = streamConfig
    this.setState({ ...this.state.streamdetail, stream })
  }

  handleCameraConfigTypeChanged(
    cameraConfigurationType: ECameraConfigurationType,
    streamId: string
  ) {
    let streamConfig = this.state.streamdetails[streamId]
    streamConfig.cameraConfig.configurationType = cameraConfigurationType
    let stream = {}
    stream[streamId] = streamConfig
    this.setState({ ...this.state.streamdetail, stream })
  }

  loadCameraFrameForStream(
    boxId: string,
    streamId: string,
    calibration: boolean,
    timestamp?: string
  ) {
    if (!this.state.streamdetails[streamId]) {
      return
    }
    let cameraConfig = this.state.streamdetails[streamId].cameraConfig
    if (
      cameraConfig.host ||
      cameraConfig.rawConnectionURI ||
      cameraConfig.configurationType ===
        ECameraConfigurationType.usbCameraConfiguration
    ) {
      this.props.loadCameraFrame(boxId, streamId, false, calibration, timestamp)
    }
  }

  handleStreamConfigurationFormSubmit(
    boxId: string,
    streamId: string,
    streamDetails: IStreamDetails
  ) {
    let updateDetail = streamDetails
    updateDetail.streamId = streamId

    // add MQTT config details to custom config
    let mqttConfig = this.state.streamdetails[streamId].mqttConfig
    if (updateDetail.mqttConfig) {
      let customConfig = mqttConfig.find(
        (item) => item.configurationType === EMqttConfigurationType.custom
      )
      customConfig && Object.assign(customConfig, updateDetail.mqttConfig)
    }
    updateDetail.mqttConfig = mqttConfig
    if (!updateDetail.mqttConfig.length) {
      return
    }

    let ioTriggerConfig = this.state.streamdetails[streamId].ioTriggerConfig
    if (updateDetail.ioTriggerConfig) {
      Object.assign(ioTriggerConfig, updateDetail.ioTriggerConfig)
    }

    updateDetail.ioTriggerConfig = ioTriggerConfig

    this.setState({
      isConfirmationDialogVisible: true,
      updatedStreamDetails: updateDetail
    })
  }

  handleStreamDelete(boxId: string, streamId: string) {
    this.setState({
      deleteStreamDetails: { boxId: boxId, streamId: streamId },
      isDeleteDialogVisible: true
    })
  }

  deleteStreamConfig() {
    this.props
      .deleteStream(
        this.state.deleteStreamDetails.boxId,
        this.state.deleteStreamDetails.streamId
      )
      .then(() => {
        notify({
          title: this.props.t('notification.configuration.saved.title'),
          message: this.props.t('notification.configuration.saved.message')
        })
        // Update license status
        this.props.callFetchLicenseStatus()
      })

    this.setState({
      isDeleteDialogVisible: false
    })
  }

  saveStreamConfig() {
    this.props
      .saveStreamDetails(
        this.props.box.id,
        this.state.updatedStreamDetails.streamId,
        this.state.updatedStreamDetails
      )
      .then((data) => {
        notify({
          title: this.props.t('notification.configuration.saved.title'),
          message: this.props.t('notification.configuration.saved.message')
        })

        if (this.isNewStream(this.state.updatedStreamDetails.streamId)) {
          this.setState({
            newStreams: this.state.newStreams.filter(
              (stream) => stream.id !== this.state.updatedStreamDetails.streamId
            )
          })
          this.loadData()
        }
        // Reload frame
        let stream = this.props.streams.find((stream) => {
          return stream.id === this.state.updatedStreamDetails.streamId
        })
        stream &&
          stream.enabled &&
          this.props.loadCameraFrame(
            this.props.box.id,
            this.state.updatedStreamDetails.streamId,
            true,
            false,
            undefined
          )
        // Update license status
        this.props.callFetchLicenseStatus()
      })
    this.setState({
      isConfirmationDialogVisible: false
    })
  }

  onCloseDialog(event) {
    if (event.target.classList.contains('bx--modal')) {
      return false
    }

    this.setState({
      isConfirmationDialogVisible: false,
      isDeleteDialogVisible: false
    })
  }

  isNewStream(streamId) {
    return !!this.state.newStreams.find(
      (newStream) => newStream.id === streamId
    )
  }

  maxStreamsReached() {
    let device = this.props.box.type
    if (!device || device === DeviceType.UNSPECIFIED) {
      return false
    }

    let numStreams = this.props.streams.filter((stream) => stream.enabled)
      .length
    let maxStreams =
      Config['MAX_STREAMS_PER_DEVICE_' + device.toUpperCase().replace('-', '_')]
    return numStreams >= maxStreams
  }

  addNewStream() {
    let id = uuidv4()
    let newStreams = this.state.newStreams
    newStreams.push(
      Object.assign({}, DefaultStream, {
        id: id,
        new: true
      })
    )
    let streamDetails = DefaultStreamDetails
    streamDetails.streamId = id
    let updatedStreamDetails = Object.assign({}, this.state.streamdetails, {})
    updatedStreamDetails[id] = streamDetails
    this.setState({
      streamdetails: updatedStreamDetails
    })
  }

  setHideBlurNote() {
    this.setState({
      hideBlurNote: true
    })
    localStorage.setItem(`${LOCAL_STORAGE_HIDEBLUR}`, 'true')
  }

  render() {
    const streams = this.props.streams
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    })
    streams.sort(function (lhs, rhs) {
      // eslint-disable-next-line eqeqeq
      if (lhs.name == undefined) {
        return 1
      }
      // eslint-disable-next-line eqeqeq
      if (rhs.name == undefined) {
        return -1
      }
      return collator.compare(lhs.name, rhs.name)
    })
    this.state.newStreams.forEach((newStream) => {
      if (!streams.find((stream) => stream.id === newStream.id)) {
        streams.push(newStream)
      }
    })
    const isLoading = this.state.isLoading
    const addStreamTooltip = () => {
      if (this.maxStreamsReached()) {
        return { title: this.props.t('configuration.maxStreamWarning') }
      } else if (
        this.props.licensesStatus &&
        !this.props.licensesStatus.streamsAvailable
      ) {
        if (userIsAtLeast(UserRole.Admin)) {
          return {
            title: (
              <span
                dangerouslySetInnerHTML={{
                  __html: this.props.t('configuration.noStreamsAvailableAdmin')
                }}
              ></span>
            )
          }
        } else {
          return { title: this.props.t('configuration.noStreamsAvailable') }
        }
      } else {
        return { title: '', visible: false }
      }
    }

    const autoExpandStream = () => {
      return streams.length === 1
    }

    const maxStreamsReached = this.maxStreamsReached()
    return (
      <>
        <Scrollbars autoHide={true}>
          {!isLoading && (
            <Accordion className="scc--stream--accordion">
              {streams.map((stream, streamIndex) => (
                <StreamConfigurationForm
                  licensesStatus={this.props.licensesStatus}
                  box={this.props.box}
                  key={stream.id}
                  streamIndex={streamIndex}
                  stream={stream}
                  cameraFrame={this.props.cameraFrames[stream.id]}
                  loadCameraFrame={this.loadCameraFrameForStream}
                  isLoadingCameraFrame={this.props.loadingIds[stream.id]}
                  streamDetails={this.state.streamdetails[stream.id]}
                  handleMqttCompressionChanged={
                    this.handleMqttCompressionChanged
                  }
                  handleMqttQosChanged={this.handleMqttQosChanged}
                  handleMqttConfigTypeChanged={this.handleMqttConfigTypeChanged}
                  handleIoTriggerConfigChanged={
                    this.handleIoTriggerConfigChanged
                  }
                  handleCameraConfigTypeChanged={
                    this.handleCameraConfigTypeChanged
                  }
                  onStreamFormSubmit={this.handleStreamConfigurationFormSubmit}
                  onStreamDelete={this.handleStreamDelete}
                  isSubmitting={this.props.isSubmitting}
                  open={this.isNewStream(stream.id) || autoExpandStream()}
                  isNew={this.isNewStream(stream.id)}
                  maxStreamsReached={maxStreamsReached}
                  hideBlurNote={this.state.hideBlurNote}
                  setHideBlurNote={this.setHideBlurNote}
                  resetCameraFrame={this.props.resetCameraFrame}
                />
              ))}
            </Accordion>
          )}
          <Tooltip placement="bottomLeft" {...addStreamTooltip()}>
            <Button
              className="scc--stream--add-button"
              type="primary"
              size="large"
              onClick={this.addNewStream}
              disabled={
                maxStreamsReached ||
                (this.props.licensesStatus &&
                  !this.props.licensesStatus.streamsAvailable)
              }
            >
              {this.props.t('configuration.addStream')}
            </Button>
          </Tooltip>
        </Scrollbars>
        <ConfigurationConfirmationDialog
          onCloseDialog={this.onCloseDialog}
          onRequestSubmit={this.saveStreamConfig}
          open={this.state.isConfirmationDialogVisible}
        />
        <DeleteConfirmationDialog
          onRequestClose={this.onCloseDialog}
          onSecondarySubmit={this.onCloseDialog}
          onRequestSubmit={this.deleteStreamConfig}
          open={this.state.isDeleteDialogVisible}
        />
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    streams: state.streams.allIds.map((id) => state.streams.byIds[id]),
    isSubmitting: state.boxDetails.isSubmitting,
    streamdetails: state.streamDetails.byIds,
    licensesStatus: state.licenses.status,
    cameraFrames: state.cameraFrames.byIds,
    loadingIds: state.cameraFrames.loadingIds
  }
}

export default withTranslation()(
  connect(mapStateToProps, {
    saveStreamDetails,
    loadCameraFrame,
    loadAllStreamDetails,
    resetCameraFrame,
    deleteStream,
    callFetchLicenseStatus
  })(StreamConfigurationContainer)
)
